import React, { useEffect, useState } from "react";

const WithdrawRequest = () => {
  const distributor_id = localStorage.getItem("distributor_id");
  const distributorName = localStorage.getItem("distributor_name");
  const [filterData, setFilterData] = useState([]);
  const getRedeemRequestData = async () => {
    let all_transaction = await fetch(
      "https://krushimitr.in/api/distributor/get-all-vd-withdraw-request"
    );
    const allRequest = await all_transaction.json();
    if (allRequest.status === 201) {
      let success = [];
      allRequest.result.map((item) => {
        if (distributor_id === item.vendorId) {
          success.push(item);
        }
      });
      setFilterData(success);
    } else {
      alert(allRequest.result);
    }
  };

  //   console.log(userData);

  useEffect(() => {
    getRedeemRequestData();
  }, [getRedeemRequestData]);
  return (
    <div className="px-4 py-3">
      <div className="row">
        <div className="col-lg-12">
          <h3>All Withdraw Request</h3>
        </div>
        <div className="col-lg-12">
          <table className="table table-bordered w-100">
            <thead>
              <tr>
                <th>Sr.No.</th>
                <th>Txn Id</th>
                {/* <th>Name</th>
                <th>Mobile No.</th> */}
                <th>UPI /Acc No.</th>
                <th>Req.Date</th>
                <th>Amount</th>
                <th>ActionDate</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {filterData.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.transactionId}</td>
                  {/* <td>{item.vendorName}</td>
                  <td>{item.vendorMobile}</td> */}
                  <td>{item.upi_acc}</td>
                  <td>{item.requestDate}</td>
                  <td>{item.amount}</td>
                  <td>{item.actionDate}</td>
                  <td>
                    {item.status === "Accepted" ? (
                      <strong className="text-success">Accepted</strong>
                    ) : item.status === "Rejected" ? (
                      <strong className="text-danger">Rejected</strong>
                    ) : (
                      <strong className="text-danger">Pending</strong>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default WithdrawRequest;
