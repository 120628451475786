import React from "react";

const Features = () => {
  const features = [
    "Wide range of farming products",
    "Secure payment gateway",
    "Android app for easy access",
    "Focus on rural needs",
    "Affordable pricing and quick delivery",
  ];

  return (
    <section className="key-features">
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="card p-4 h-100">
              <h4>Key Features of CountyBiz:</h4>
              <ul>
                <li>A one-stop shop for farming essentials and daily needs.</li>
                <li>Secure and reliable payment options.</li>
                <li>Affordable pricing to fit rural budgets.</li>
                <li>Quick and reliable delivery services.</li>
                <li>
                  A user-friendly Android app and web app for easy access.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card p-4 h-100">
              <h4>Our Mission:</h4>
              <p c>
                To empower rural communities by making high-quality products and
                services easily accessible, fostering growth, and improving the
                quality of life for every household.
              </p>

              <p className="mb-0">
                With CountyBiz, rural shopping becomes smarter, easier, and
                better. Join us on our journey to revolutionize rural eCommerce!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const styles = {
  container: {
    padding: "50px 20px",
  },
  list: {
    listStyle: "none",
    padding: 0,
  },
  listItem: {
    marginBottom: "10px",
  },
};

export default Features;
