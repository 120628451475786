import React from "react";
import About from "./About";
import Features from "./Features";
import Contact from "./Contact";
import Carousel from "./Carousel";

const Index = () => {
  return (
    <div>
      <Carousel />
      <About />
      <Features />
      <Contact />
      {/* <Footer /> */}
    </div>
  );
};

export default Index;
