import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { url } from "../helper/apiHelper";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useNavigate } from "react-router";
import loading from "../images/loading.gif";
const AllAdsRequest = () => {
  const [loadings, setLoadings] = useState(false);
  const navigate = useNavigate()
  const [time, setTime] = useState(false);

  const [duration, setDuration] = useState("");
  const [price, setPrice] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");

  const [classifiedCategory, setClassifiedCategory] = useState([]);
  const getClassifiedCategoryData = async () => {
    let all_category = await fetch(
      `${url}/api/admin/all-classified-category`
    );
    const getCat = await all_category.json();
    const data = []
    getCat.getCate.map(item => {
      data.push({ key: item._id, value: item.classified_category_name })
    })

    setClassifiedCategory(data);
  };

  const [globalFilter, setGlobalFilter] = useState(null);
  const [allData, setAllData] = useState([]);
  useEffect(() => {
    getClassifiedCategoryData()
    const getClassifiedAds = async () => {
      const result = await fetch(
        `${url}/api/admin/all-classified-ads`
      ).then((result) => result.json());
      setAllData(result.result);
    };
    getClassifiedAds();
  }, [time]);


  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const openDialog = () => {
    setEditModal(true)
  }
  const openAddDialog = () => {
    setAddModal(true)
  }
  const hideDialog = () => {
    setEditModal(false)
    setAddModal(false)
  }

  const rejectApplication = async (id) => {
    let resultDel = await fetch(
      `${url}/api/admin/reject-application-form`,
      {
        method: "post",
        body: JSON.stringify({ id }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((resultDel) => resultDel.json());
    if (resultDel.status === 201) {
      alert(resultDel.result);
      setTime(true);
    } else {
      alert(resultDel.result);
    }
  };

  const [selectedData, setSelectedData] = useState([]);
  const filterApplyTemplate = (options) => {
    if (options.status === "Pending") {
      return (
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-primary btn-sm me-1"
            onClick={() => { openDialog(); setSelectedData(options) }}
            data-bs-toggle="tooltip" data-bs-placement="top" title="Accept"
          >
            <i className="fas fa-eye"></i>
          </button>
          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={() => { rejectApplication(options._id) }}
            data-bs-toggle="tooltip" data-bs-placement="top" title="Reject"
          >
            <i class="fa fa-ban" aria-hidden="true"></i>
          </button>
        </div>
      );
    }
  };

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    setTitle(selectedData.title)
    setDescription(selectedData.description)
  }, []);

  const [date1, setDate1] = useState(null);
  const headerComplete = (
    <div className="py-2">
      <div className="row">
        <div className="col-lg-6 d-flex">
          <h4 className="m-0">All Classified Ads</h4>
        </div>
        <div className="col-lg-4">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search..."
              className="form-control ps-5"
            />
          </span>
        </div>
        <div className="col-lg-2">
          <button className="btn btn-outline-primary btn-sm" onClick={() => openAddDialog()}>Add Ads</button>
        </div>
      </div>
    </div>
  );
  const downloadImage = (url, filename) => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'image/jpeg', // adjust this to the correct type if needed
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.blob();
      })
      .then((blob) => {
        // Create a temporary URL for the blob object
        const objectUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectUrl;
        link.setAttribute('download', filename || 'image.jpg');

        // Append link to body and trigger download
        document.body.appendChild(link);
        link.click();

        // Clean up by removing the link and revoking the object URL
        link.remove();
        window.URL.revokeObjectURL(objectUrl);
      })
      .catch((error) => console.error('Download failed:', error));
  };

  const images = (rowData) => {
    if (rowData.afterImage === undefined || rowData.afterImage === null || rowData.afterImage === "") {
      return (
        <img
          src={`${url}/upload/${rowData.image}`} width={100} height={100} alt=""
          style={{ cursor: 'pointer' }}
          onClick={() => downloadImage(`${url}/upload/${rowData.image}`, rowData.image)}
        />
      );
    } else {
      return (
        <img
          src={`${url}/upload/${rowData.afterImage}`} width={100} height={100} alt=""
          style={{ cursor: 'pointer' }}
          onClick={() => downloadImage(`${url}/upload/${rowData.afterImage}`, rowData.afterImage)}
        />
      );
    }
  };

  const editDialogFooter = () => (
    <React.Fragment>
      <Button label="Close" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button label="Update" outlined severity="success" onClick={() => updateAds()} />
    </React.Fragment>
  );

  const addDialogFooter = () => (
    <React.Fragment>
      <Button label="Close" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button label="Submit" outlined severity="success" onClick={() => storeAds()} />
    </React.Fragment>
  );
  const [selectedCategory, setSelectedCategory] = useState("");
  const storeAds = async () => {
    setLoadings(true);
    if (!title || !selectedCategory || !duration) {
      alert("Select fill mandatory fields")
      setLoadings(false);
    } else {
      let dura;
      if (duration.includes("Days")) {
        dura = duration.split("Days");
      } else if (duration.includes("Months")) {
        dura = duration.split("Months");
      }
      let expiryDate;
      if (dura[0] == 15) {
        expiryDate = moment(new Date()).add(15, 'days');
      } else {
        expiryDate = moment(new Date()).add(dura[0], 'months');
      }
      const formData = new FormData();
      formData.append("userId", "");
      formData.append("userName", name);
      formData.append("userMobile", mobile);
      formData.append("selectedCategory", selectedCategory);
      formData.append("selectedDuration", duration);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("paymentStatus", "Paid");
      formData.append("amount", price);
      formData.append("duration", duration);
      formData.append("expiryDate", expiryDate.format('DD-MM-YYYY'));
      formData.append("status", "Done");
      formData.append("image", image);
      await fetch(`${url}/api/admin/store_ads`, {
        method: 'POST',
        body: formData,
      }).then((response) => response.json())
        .then((response) => {
          if (response.status === 201) {
            setAddModal(false)
            alert(response.result)
            setLoadings(false);
          } else {
            alert(response.result)
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  };

  const updateAds = async () => {
    setLoadings(true);
    let dura;
    let duration = selectedData.duration;
    if (duration.includes("Days")) {
      dura = duration.split("Days");
    } else if (duration.includes("Month")) {
      dura = duration.split("Month");
    }
    const formData = new FormData();
    formData.append("adsId", selectedData._id);
    formData.append("title", title ? title : selectedData.title);
    formData.append("description", description ? description : selectedData.description);
    formData.append("dura", dura[0]);
    formData.append("image", image);
    await fetch(`${url}/api/admin/update_ads`, {
      method: 'POST',
      body: formData
    }).then((response) => response.json())
      .then((response) => {
        // console.log(response);

        if (response.status === 201) {
          setTime(true)
          hideDialog()
          alert(response.result);
          setLoadings(false);
        } else {
          hideDialog()
          alert(response.result)
          setLoadings(false);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const checkStatus = (rowData) => {
    if (rowData.status === "Pending" || rowData.status === "Reject") {
      return (
        <label style={{ fontSize: 14 }} className="text-danger">{rowData.status}</label>
      );
    } else {
      return (
        <label style={{ fontSize: 14 }} className="text-success">{rowData.status}</label>
      );
    }
  }

  const getPublishedNow = async (id, duration) => {
    let publish = "Done"
    let dura;

    if (duration.includes("Days")) {
      dura = duration.split("Days");
    } else if (duration.includes("Month")) {
      dura = duration.split("Month");
    }
    const result = await fetch(`${url}/api/users/get-approve-now`, {
      method: "post",
      body: JSON.stringify({ id, publish, dura: dura[0] }),
      headers: {
        "Content-Type": "application/json"
      }
    })
    const res = await result.json();
    if (res.status === 201) {
      alert(res.result);
      window.location.reload();
    } else {
      alert(res.result);
    }
  }

  const descriptionChange = (rowData) => {
    return <p className="descriptionDiv">
      {rowData.description}
    </p>
  }

  return (
    <>
      <div
        className="card px-3"
        disabled={loadings ? "disabled" : ""}
      >
        <img
          src={loading}
          className={"loader " + (loadings ? "d-block" : "d-none")}
          alt=""
        />
        <DataTable
          value={allData}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
          globalFilter={globalFilter}
          header={headerComplete}
        >
          <Column
            field="#"
            header="#"
            bodyStyle={{
              fontSize: 15,
              fontWeight: "bold",
              textAlign: "center",
            }}
            body={(data, options) => options.rowIndex + 1}
          ></Column>
          <Column field="createdDate" header="Date" sortable></Column>
          <Column field="selectedCategory" header="Category" sortable></Column>
          <Column field="title" header="Title" sortable></Column>
          <Column field="description" header="Description" body={descriptionChange}></Column>
          <Column field="amount" header="Amount"></Column>
          <Column field="duration" header="duration" sortable ></Column>
          <Column field="paymentStatus" header="PayStatus"  ></Column>
          <Column field={checkStatus} header="Status" body={checkStatus} sortable></Column>
          <Column
            field={images}
            header="Image"
            body={images}
          ></Column>
          <Column
            header="Action"
            body={filterApplyTemplate}
            severity="success"
          ></Column>
        </DataTable>

        <Dialog
          visible={editModal}
          style={{ width: "40rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal

          footer={editDialogFooter} onHide={hideDialog} >
          <div disabled={loadings ? "disabled" : ""}>
            <img
              src={loading}
              className={"loader " + (loadings ? "d-block" : "d-none")}
              alt=""
            />
            <div className="row" >
              <div className="col-lg-6">
                <label>Category</label>
                <input type="text" className="form-control" readOnly value={selectedData.selectedCategory} />
              </div>
              <div className="col-lg-6">
                <label>Title</label>
                <input type="text" className="form-control" onChange={(e) => setTitle(e.target.value)} defaultValue={selectedData.title} />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-12">
                <label>Description</label>
                <textarea type="text" className="form-control" onChange={(e) => setDescription(e.target.value)} rows={5} defaultValue={selectedData.description} ></textarea>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-4">
                <label>Duration</label>
                <input type="text" className="form-control" readOnly value={selectedData.duration} />
              </div>
              <div className="col-lg-4">
                <label>Amount</label>
                <input type="text" className="form-control" readOnly value={selectedData.amount} />
              </div>
              <div className="col-lg-4">
                <label>Image</label>
                <img
                  src={`${url}/upload/${selectedData.image}`} width={100} height={100} alt=""
                  style={{ cursor: 'pointer' }}
                  onClick={() => downloadImage(`${url}/upload/${selectedData.image}`, selectedData.image)}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-12">
                <label>Upload Image <small className="text-danger fw-bold">(If any changes)</small></label>
                <input type="file" className="form-control" onChange={(e) => setImage(e.target.files[0])} />
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          visible={addModal}
          style={{ width: "40rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal

          footer={addDialogFooter} onHide={hideDialog} >
          <div disabled={loadings ? "disabled" : ""}>
            <img
              src={loading}
              className={"loader " + (loadings ? "d-block" : "d-none")}
              alt=""
            />
            <div className="row" >
              <div className="col-lg-6">
                <label>Name</label>
                <input type="text" className="form-control" placeholder="Enter Full Name" onChange={(e) => setName(e.target.value)} />
              </div>
              <div className="col-lg-6">
                <label>Mobile No.</label>
                <input type="text" className="form-control" placeholder="Enter Mobile Number" onChange={(e) => setMobile(e.target.value)} />
              </div>
            </div>
            <div className="row" >
              <div className="col-lg-6">
                <label>Category</label>
                <select
                  name="duration"
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  className="form-select"
                >
                  <option value={""}>Select One</option>
                  {
                    classifiedCategory.map(item => {
                      return <option value={item.value}>{item.value}</option>
                    })
                  }

                </select>
              </div>
              <div className="col-lg-6">
                <label>Title</label>
                <input type="text" className="form-control" placeholder="Enter Title" onChange={(e) => setTitle(e.target.value)} />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-12">
                <label>Description</label>
                <textarea type="text" className="form-control" placeholder="Enter Description" onChange={(e) => setDescription(e.target.value)} rows={3}  ></textarea>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-6">
                <label>Duration</label>
                <select
                  name="duration"
                  onChange={(e) => setDuration(e.target.value)}
                  className="form-select"
                >
                  <option value={""}>Select One</option>
                  <option value={"15Days"}>15 Days</option>
                  <option value={"1Months"}>1 Months</option>
                  <option value={"2Months"}>2 Months</option>
                  <option value={"3Months"}>3 Months</option>
                  <option value={"6Months"}>6 Months</option>
                  <option value={"12Months"}>12 Months</option>
                </select>
              </div>
              <div className="col-lg-6">
                <label>Amount</label>
                <input
                  type="number"
                  name="price"
                  onChange={(e) => setPrice(e.target.value)}
                  placeholder="Amount"
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-12">
                <label>Upload Image</label>
                <input type="file" className="form-control" onChange={(e) => setImage(e.target.files[0])} />
              </div>
            </div>
          </div>
        </Dialog>

      </div>
    </>
  );
}


export default AllAdsRequest