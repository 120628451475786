import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { url } from "../helper/apiHelper";
const ClassifiedPackages = () => {
    const [duration, setDuration] = useState("");
    const [price, setPrice] = useState("");
    const [isSet, setIsSet] = useState(false);
    const [quote, setQuote] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        price: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        duration: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        status: {
            operator: FilterOperator.OR,
            constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    const storePackages = async () => {
        if (!price || !duration) {
            alert("Filled All Fields")
        } else {
            let results = await fetch(`${url}/api/admin/add-classified-package`, {
                method: "post",
                body: JSON.stringify({
                    duration,
                    price
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            });
            let result = await results.json();
            if (result.status === 201) {
                alert(result.result);
                setIsSet(false);
                setDuration("");
                setQuote(true);
                window.location.reload();
            } else {
                alert(result.result);
            }
        }
    };
    const DeleteOne = async (id) => {
        let resultDel = await fetch(
            `${url}/api/admin/delete-classified-package`,
            {
                method: "post",
                body: JSON.stringify({ id }),
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }
        ).then((resultDel) => resultDel.json());

        if (resultDel.status === 201) {
            alert(resultDel.result);
            setQuote(true);
        } else {
            alert(resultDel.result);
        }
    };

    const [packages, setPackages] = useState([]);
    const getClassifiedPackagesData = async () => {
        let all_category = await fetch(
            `${url}/api/admin/all-classified-packages`
        );
        const getCat = await all_category.json();
        setFilterData(getCat.getClassifiedPackages);
        setPackages(getCat.getClassifiedPackages);
    };
    useEffect(() => {
        getClassifiedPackagesData();
    }, [quote]);



    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const headerComplete = (
        <div className="py-2">
            <div className="row">
                <div className="col-lg-5 d-flex">
                    <h4 className="m-0">ALL Classified Packages</h4>
                </div>
                <div className="col-lg-5">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            placeholder="Keyword Search"
                            className="form-control ps-5"
                        />
                    </span>
                </div>
                <div className="col-lg-2">
                    <button
                        type="button"
                        className="btn btn-primary float-end"
                        data-bs-toggle="modal"
                        onClick={() => setIsSet(true)}
                        data-bs-target="#exampleModal"
                    >
                        Add Packages
                    </button>
                </div>
            </div>
        </div>
    );
    const filterApplyTemplate = (options) => (
        <>
            <button
                type="button"
                onClick={() => DeleteOne(options._id)}
                className="btn btn-danger btn-sm"
            >
                <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
        </>
    );

    const DurationCheck = (rowData) => {
        if (rowData.duration === "15") {
            return "15 Days"
        } else {
            return rowData.duration + " Month"
        }
    }


    return (
        <>
            <div className="card p-3">
                <div className="table-responsive" style={{ overflow: "auto" }}>
                    <DataTable
                        value={filterData}
                        dataKey="_id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Categories"
                        globalFilter={globalFilter}
                        header={headerComplete}
                        filters={filters}
                        filterDisplay="menu"
                        globalFilterFields={[
                            "duration",
                            "price",
                            "status",
                        ]}
                    >
                        <Column
                            field="#"
                            header="Sr. No."
                            bodyStyle={{
                                fontSize: 15,
                                fontWeight: "bold",
                                textAlign: "center",
                            }}
                            body={(data, options) => options.rowIndex + 1}
                        ></Column>
                        <Column
                            field="price"
                            header="Price"
                            bodyStyle={{ fontWeight: "bold" }}
                            sortable
                        ></Column>
                        <Column
                            field="duration"
                            header="Duration"
                            body={DurationCheck}
                            sortable
                        ></Column>
                        <Column field="status" header="Status"></Column>
                        <Column
                            header="Action"
                            body={filterApplyTemplate}
                        ></Column>
                    </DataTable>
                </div>
                <div
                    className={`modal fade ${isSet ? "show" : ""} `}
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">
                                    Add Classified Packages
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body p-4">
                                <div className="row">
                                    <label htmlFor="">Duration</label>
                                    <select
                                        name="duration"
                                        onChange={(e) => setDuration(e.target.value)}
                                        className="form-select"
                                    >
                                        <option value={""}>Select One</option>
                                        <option value={"15"}>15 Days</option>
                                        <option value={"1"}>1 Months</option>
                                        <option value={"2"}>2 Months</option>
                                        <option value={"3"}>3 Months</option>
                                        <option value={"6"}>6 Months</option>
                                        <option value={"12"}>12 Months</option>
                                    </select>
                                </div>
                                <div className="row mt-3">
                                    <label htmlFor="">
                                        Price
                                    </label>
                                    <input
                                        type="number"
                                        name="price"
                                        onChange={(e) => setPrice(e.target.value)}
                                        placeholder="Price"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    onClick={storePackages}
                                    className="btn btn-primary"
                                >
                                    Save changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default ClassifiedPackages