import React from "react";

const About = () => {
  return (
    <section className="about-section">
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-lg-5 p-3">
            <img src="./assets/logo.png" className="border-1 rounded-3" width={"100%"} />
          </div>
          <div className="col-lg-7">
            <h2>About CountyBiz</h2>
            <p>
              CountyBiz is not just another eCommerce platform—it’s a mission to
              transform the way rural communities access essential products and
              services. Designed specifically for rural areas, CountyBiz
              provides an extensive range of farming equipment, seeds,
              fertilizers, and other agricultural necessities.
            </p>
            <p>
              But it doesn’t stop there. The platform also caters to daily
              needs, including groceries, household items, and more, all
              delivered right to your doorstep. With an easy-to-use Android app
              and web application, CountyBiz ensures that even first-time users
              can shop effortlessly.
            </p>
            <p>
              At CountyBiz, we believe that every rural household deserves
              access to high-quality, affordable products. By bridging the gap
              between suppliers and rural customers, we aim to create a seamless
              shopping experience that empowers local farmers, households, and
              businesses.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
