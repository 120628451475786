import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const StockManagement = () => {
  const navigate = useNavigate();
  const [time, setTime] = useState(false);
  const [isSet, setIsSet] = useState(false);
  const [products, setProducts] = useState([]);
  const [soldOfflineDialog, setsoldOfflineDialogDialog] = useState(false);
  const distributor_id = localStorage.getItem("distributor_id");
  const [soldOfflineDataDialog, setSoldOfflineDataDialog] = useState(false);

  const [distributor, setDistributor] = useState([]);
  const getDistributor = async () => {
    const response = await fetch(
      "https://krushimitr.in/api/distributor/distributor-profile",
      {
        method: "post",
        body: JSON.stringify({ distributor_id }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    if (data.status === 201) {
      setDistributor(data.distributor);
    } else {
      setDistributor("");
    }
  };

  //Get All Products
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getProductData = async () => {
    let all_products = await fetch(
      "https://krushimitr.in/api/admin/all-products"
    );
    const getProd = await all_products.json();
    if (getProd.status === 201) {
      setProducts(getProd.product_data);
      // console.log(getProd.product_data);
    } else {
      setProducts(getProd.result);
    }
  };

  //Get All Category
  const [cate, setCate] = useState([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCategoryData = async () => {
    let all_category = await fetch(
      "https://krushimitr.in/api/admin/all-category"
    );
    const getCat = await all_category.json();
    setCate(getCat.getCate);
  };

  useEffect(() => {
    getCategoryData();
    getProductData();
    getDistributor();
    setTime(false);
  }, []);

  const hidesoldOfflineDialogDialog = () => {
    setsoldOfflineDialogDialog(false);
    setSoldOfflineDataDialog(false);
  };

  const [productName, setProductName] = useState("");
  const [productSize, setProductSize] = useState("");
  const [productId, setProductId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [itemsData, setItemsData] = useState([]);
  const [productSelectedSize, setProductSelectedSize] = useState([]);
  const [UpdatedSize, setUpdatedSize] = useState("");

  const getSelectedProductData = (data) => {
    setProductId(data);
    products.map((item) => {
      if (item._id === data) {
        setProductName(item.productName);
        setProductSize(item.size);
      }
    });
  };
  const changeQuantity = (qty) => {
    setQuantity(qty);
    products.map((item) => {
      if (item._id === productId) {
        let sizeArr = [];
        item.size.map((e) => {
          let ee = JSON.parse(e);
          let main = ee.size + "(" + ee.unit + ")";
          if (main === productSelectedSize && parseInt(ee.quantity) > qty) {
            ee.quantity = parseInt(ee.quantity) - parseInt(qty);
            ee.remQuantity = ee.quantity;
            sizeArr.push(ee);
          } else {
            sizeArr.push(ee);
          }
        });
        setUpdatedSize(sizeArr);
      }
    });
  };

  const updateStock = async () => {
    let sizes = [];
    Object.values(UpdatedSize).forEach((item) => {
      sizes.push(JSON.stringify(item));
    });
    const response = await fetch(
      "https://krushimitr.in/api/distributor/vendors-update-stock",
      {
        method: "post",
        body: JSON.stringify({
          productName,
          productId,
          customerName,
          mobileNo,
          quantity,
          sizes,
          productSelectedSize,
          vendorId: distributor_id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    if (data.status === 201) {
      alert(data.result);
      window.location.reload();
    } else {
      alert(data.result);
    }
  };
  const [soldData, setSoldData] = useState([]);
  const getQuantityData = async (productId) => {
    const response = await fetch(
      "https://krushimitr.in/api/distributor/vendor-sold-data",
      {
        method: "post",
        body: JSON.stringify({
          distributor_id,
          productId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    if (data.status === 201) {
      setSoldData(data.result);
    } else {
      alert(data.result);
    }
  };

  return (
    <div className="card p-3">
      <div className="row">
        <div className="col-lg-8">
          <h2 className="text-uppercase">Stock Management</h2>
        </div>
        <div className="col-lg-4 text-end"></div>
      </div>
      <hr />
      <div className="table-responsive" style={{ overflow: "auto" }}>
        <table className="table table-bordered stockMgmt">
          <thead className="table-dark">
            <tr>
              <th scope="col">Category</th>
              <th scope="col">Product Name</th>
              <th scope="col">Sizes</th>
              <th scope="col">Image</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) =>
              distributor_id === product.vendor_id ? (
                <tr key={product._id}>
                  <td>{product.category}</td>
                  <td>{product.productName}</td>
                  <td>
                    <table className="table table-bordered mt-2">
                      <thead>
                        <tr>
                          <th>Size</th>
                          <th>Price</th>
                          <th>Buying Rate</th>
                          <th>GST</th>
                          <th>Quantity</th>
                          <th>Rem. Qty.</th>
                        </tr>
                      </thead>
                      <tbody>
                        {product.size === ""
                          ? ""
                          : product.size.map((item) => {
                              item = JSON.parse(item);
                              return (
                                <tr>
                                  <td style={{ fontSize: 14 }}>
                                    {item.size}
                                    {item.unit}
                                  </td>
                                  <td>{item.selling_price}rs</td>
                                  <td>{item.buying_price}rs</td>
                                  <td>{item.gst}</td>
                                  <td>{item.quantity}</td>
                                  <td>
                                    {item.remQuantity === 0
                                      ? item.quantity
                                      : item.remQuantity}
                                  </td>
                                </tr>
                                //   <li className="my-0  py-0 list-group-item list-group-item-primary">
                                //     {item.size}
                                //     {item.unit} - {item.selling_price}rs
                                //   </li>
                              );
                            })}
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <img
                      src={`https://krushimitr.in/upload/${product.image[0]}`}
                      width={"80px"}
                      height={"80px"}
                      alt={product.category_image}
                    />
                  </td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setsoldOfflineDialogDialog(true);
                        getSelectedProductData(product._id);
                      }}
                    >
                      Sold Offline
                    </button>
                    <button
                      className="btn btn-info ms-1"
                      onClick={() => {
                        setSoldOfflineDataDialog(true);
                        getQuantityData(product._id);
                      }}
                    >
                      <i className="fa fa-eye"></i>
                    </button>
                  </td>
                </tr>
              ) : (
                ""
              )
            )}
          </tbody>
        </table>
      </div>
      <Dialog
        visible={soldOfflineDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Offline Sold"
        modal
        onHide={hidesoldOfflineDialogDialog}
      >
        <div className="row">
          <div className="col-lg-12">
            <label>Select Product</label>
            <input
              type="text"
              className="form-select form-control"
              name="product"
              defaultValue={productName}
              readOnly
            />
          </div>
          <div className="col-lg-8 mt-2">
            <label>Select Size</label>
            <select
              className="form-select form-control"
              name="sizes"
              onChange={(e) => {
                setProductSelectedSize(e.target.value);
              }}
            >
              <option value="">Select Size</option>
              {productSize &&
                productSize.map((item) => {
                  let abc = JSON.parse(item);
                  return (
                    <option value={abc.size + "(" + abc.unit + ")"}>
                      {abc.size + "(" + abc.unit + ")"}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-4 mt-2">
            <label>Enter Quantity</label>
            <input
              type="number"
              name="quantity"
              className="form-control"
              onChange={(e) => changeQuantity(e.target.value)}
            />
          </div>
          <div className="col-lg-7 mt-2">
            <label>Customer Name</label>
            <input
              type="text"
              name="name"
              className="form-control"
              onChange={(e) => setCustomerName(e.target.value)}
            />
          </div>
          <div className="col-lg-5 mt-2">
            <label>Customer Mobile No.</label>
            <input
              type="number"
              name="mobile"
              className="form-control"
              onChange={(e) => setMobileNo(e.target.value)}
            />
          </div>
          <div className="col-lg-4 m-auto">
            <button className="btn btn-info mt-3" onClick={() => updateStock()}>
              Update Now
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={soldOfflineDataDialog}
        style={{ width: "50rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="View Data"
        modal
        onHide={hidesoldOfflineDialogDialog}
      >
        <div className="row">
          <div className="col-lg-12">
            <table className="table table-bordered mt-2">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Size</th>
                  <th>Quantity</th>
                  <th>Customer Name</th>
                  <th>Customer Mobile</th>
                </tr>
              </thead>
              <tbody>
                {soldData &&
                  soldData.map((item) => {
                    return (
                      <tr>
                        <td>{item.productName}</td>
                        <td>{item.productSelectedSize}</td>
                        <td>{item.quantity}</td>
                        <td>{item.customerName}</td>
                        <td>{item.mobileNo}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default StockManagement;
