import React from "react";
import { Link } from "react-router-dom";

const Carousel = () => {
  return (
    <div
      id="carouselExampleFade"
      class="carousel slide carousel-fade position-relative carouselSlider"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="./assets/bg2.jpg" class="d-block w-100" alt="..." />
        </div>
        <div class="carousel-item">
          <img src="./assets/bg1.jpg" class="d-block w-100" alt="..." />
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleFade"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleFade"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
      <div className="hero-section">
        <h1 className="text-white">Welcome to CountyBiz</h1>
        <p className="text-white">
          Your one-stop solution for rural eCommerce and farming needs.
        </p>
        <div style={styles.buttons}>
          <Link
            to="https://play.google.com/store/apps/details?id=com.countybizagri"
            style={styles.button}
            target="_blank"
          >
            Download Android App
          </Link>
        </div>
      </div>
    </div>
  );
};

const styles = {
  buttons: {
    marginTop: "20px",
  },
  button: {
    padding: "10px 20px",
    margin: "5px",
    textDecoration: "none",
    color: "white",
    backgroundColor: "#007bff",
    borderRadius: "5px",
  },
};

export default Carousel;
