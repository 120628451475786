import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { url } from "../helper/apiHelper";

const AllAppOtherData = () => {
  const [isSet, setIsSet] = useState(false);
  const [title, setTitle] = useState("");
  const [Description, setDescription] = useState("");
  const [image, setImage] = useState([]);
  const [date, setDate] = useState("");
  const [allData, setAllData] = useState([]);
  const [Link, setLink] = useState([]);
  const [otherCategoryId, setOtherCategoryId] = useState("");
  const [otherCategoryName, setOtherCategoryName] = useState("");

  const storeData = async () => {
    const formData = new FormData();
    formData.append("otherCategoryId", otherCategoryId);
    formData.append("otherCategoryName", otherCategoryName);
    formData.append("Title", title);
    formData.append("Description", Description);
    Object.values(image).forEach((file) => {
      formData.append("multi_image", file);
    });
    formData.append("date", date);
    formData.append("Link", Link);
    const result = await fetch(
      `${url}/api/admin/add-other-category-data`,
      {
        method: "POST",
        body: formData,
      }
    ).then((result) => result.json());
    if (result.status === 201) {
      setIsSet(true);
      alert(result.result);
      window.location.reload();
    } else {
      alert(result.result);
    }
  };
  const DeleteOne = async (id) => {
    let resultDel = await fetch(
      `${url}/api/admin/delete-other-category-data`,
      {
        method: "post",
        body: JSON.stringify({ id }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    ).then((resultDel) => resultDel.json());
    if (resultDel.status === 201) {
      setIsSet(true);
      alert(resultDel.result);
      window.location.reload();
    } else {
      alert(resultDel.result);
    }
  };
  const getData = async () => {
    const result = await fetch(
      `${url}/api/admin/get-other-category-data`
    ).then((result) => result.json());
    setAllData(result.getData);
  };
  const [cate, setCate] = useState([]);
  const getCategoryData = async () => {
    let all_category = await fetch(
      `${url}/api/admin/all-app-other-category`
    );
    const getCat = await all_category.json();
    setCate(getCat.getCate);
  };
  useEffect(() => {
    getData();
    getCategoryData();
  }, [isSet]);
  const getOtherCategory = (id) => {
    setOtherCategoryId(id);
    cate.map((ele) => {
      if (ele._id == id) {
        setOtherCategoryName(ele.app_category_name);
      }
    });
  };
  return (
    <>
      <div className="card p-3">
        <div className="row">
          <div className="col-lg-8">
            <h2 className="text-uppercase">All Data</h2>
          </div>
          <div className="col-lg-4">
            <button
              type="button"
              className="btn btn-primary float-end"
              data-bs-toggle="modal"
              onClick={() => setIsSet(true)}
              data-bs-target="#exampleModal"
            >
              Add Data
            </button>
          </div>
        </div>
        <hr />

        <div className="table-responsive" style={{ overflow: "auto" }}>
          <table className="table table-hover table-bordered">
            <thead className="table-dark">
              <tr>
                <th scope="col">Category</th>
                <th scope="col">Title</th>
                <th scope="col">Description</th>
                <th scope="col">Date</th>
                <th scope="col">Link</th>
                <th scope="col">Image</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {allData.map((data) => (
                <tr key={data._id}>
                  <td>{data.otherCategoryName}</td>
                  <td>{data.title}</td>
                  <td>
                    <div style={{ height: "100px",fontSize:13, overflow: "scroll" }}>
                      {data.description}
                    </div>
                  </td>
                  <td>{data.date}</td>
                  <td>
                    <div style={{ width: "140px",fontSize:13, overflow: "scroll" }}>
                      {data.link}
                    </div>
                  </td>
                  <td>
                    <img
                      src={`${url}/upload/${data.image[0]}`}
                      width={"100px"}
                      alt={data.image}
                    />
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm me-1"
                      data-bs-toggle="modal"
                      data-bs-target={`#editModal` + data._id}
                    >
                      <i className="fas fa-edit"></i>
                    </button>
                    <button
                      type="button"
                      onClick={() => DeleteOne(data._id)}
                      className="btn btn-danger btn-sm me-1"
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div
          className={`modal fade ${isSet ? "show" : ""} `}
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header bg-secondary py-2">
                <h1
                  className="modal-title fs-5 text-white"
                  id="exampleModalLabel"
                >
                  Add Data
                </h1>
                <button
                  type="button"
                  className="btn-close text-white pt-4"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body p-4">
                <div className="row">
                  <div className="col-lg-8">
                    <label htmlFor="">Select Category </label>
                    <select
                      className="form-control form-select"
                      onChange={(e) => getOtherCategory(e.target.value)}
                    >
                      <option value="">Select One</option>
                      {cate.map((item) => (
                        <option value={item._id}>
                          {item.app_category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-8">
                    <label htmlFor="">Title </label>
                    <input
                      type="text"
                      name="productName"
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="Title"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="">Date</label>
                    <DatePicker
                      selected={date}
                      onChange={(date) => setDate(date)}
                      dateFormat={"dd-MM-yyyy"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="">Description</label>
                    <textarea
                      name="desc"
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Description"
                      className="form-control"
                    ></textarea>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-8">
                    <label htmlFor=""> Link</label>
                    <input
                      type="text"
                      name="link"
                      onChange={(e) => setLink(e.target.value)}
                      placeholder=" Link"
                      className="form-control"
                    />
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor=""> Image</label>
                    <input
                      type="file"
                      name="product_img"
                      multiple
                      accept="image/*"
                      onChange={(e) => setImage(e.target.files)}
                      placeholder="Product Image"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer py-1">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  onClick={storeData}
                  data-bs-dismiss="modal"
                  className="btn btn-primary"
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllAppOtherData;
