import React from "react";

const Contact = () => {
  return (
    <section className="contact">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-6">
            <div className="card p-4 h-100">
              <h4>Contact Details</h4>
              <div className="card p-4 mt-2 text-center">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                <p>Chh. Sambhajinagar, Maharashtra</p>
              </div>
              <div className="card p-4 mt-3 text-center">
                <i class="fa fa-phone" aria-hidden="true"></i>
                <p>+918888073798</p>
              </div>
              <div className="card p-4 mt-3 text-center">
                <i class="fa fa-envelope" aria-hidden="true"></i>
                <p>support@countybiz.in</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card p-4 h-100">
              <h4>Contact Us</h4>
              <div className="row">
                <div className="col-lg-12">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Full Name"
                  />
                </div>
                <div className="col-lg-6 mt-3">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Mobile Number"
                  />
                </div>
                <div className="col-lg-6 mt-3">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Email"
                  />
                </div>
                <div className="col-lg-12 mt-3">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Subject"
                  />
                </div>
                <div className="col-lg-12 mt-3">
                  <textarea
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Message"
                  ></textarea>
                </div>
                <div className="col-lg-12 mt-3 text-center">
                  <button className="btn btn-lg btn-primary">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
