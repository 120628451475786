import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { url } from "../helper/apiHelper";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useNavigate } from "react-router";

const AllPincodes = () => {
    const navigate = useNavigate()
    const [time, setTime] = useState(false);
    const deleteApplication = async (id) => {
        let resultDel = await fetch(
            `${url}/api/admin/delete-pincode`,
            {
                method: "post",
                body: JSON.stringify({ id }),
                headers: {
                    "Content-Type": "application/json",
                },
            }
        ).then((resultDel) => resultDel.json());
        if (resultDel.status === 201) {
            setTime(true)
            alert(resultDel.result);
        } else {
            setTime(true)
            alert(resultDel.result);
        }
    };

    const [globalFilter, setGlobalFilter] = useState(null);
    const [allData, setAllData] = useState([]);
    useEffect(() => {
        const getClassifiedAds = async () => {
            const result = await fetch(
                `${url}/api/admin/all-pincodes`
            ).then((result) => result.json());
            setAllData(result.result);
        };
        getClassifiedAds();
        setTime(false)
    }, [time, navigate]);

    const [selectedData, setSelectedData] = useState([]);
    const [pincode, setPincode] = useState("");
    const [deliveryAddress, setDeliveryAddress] = useState("");
    const [editModal, setEditModal] = useState(false);
    const [addModal, setAddModal] = useState(false);

    const openAddDialog = () => {
        setAddModal(true)
    }
    const openDialog = (options) => {
        setSelectedData(options);
        setEditModal(true)
    }
    const hideDialog = () => {
        setAddModal(false)
        setEditModal(false)
    }


    const filterApplyTemplate = (options) => {
        return (
            <>
                <button
                    type="button"
                    className="btn btn-info btn-sm w-25 me-1"
                    onClick={() => { openDialog(options) }}
                >
                    <i className="fas fa-edit text-white"></i>
                </button>
                <button
                    type="button"
                    className="btn btn-danger btn-sm w-25 ms-1"
                    onClick={() => deleteApplication(options._id)}
                >
                    <i className="fas fa-trash"></i>
                </button>
            </>
        );
    };
    useEffect(() => {
        setPincode(selectedData.pincode)
        setDeliveryAddress(selectedData.deliveryAddress)
    }, [])


    const headerComplete = (
        <div className="py-2">
            <div className="row">
                <div className="col-lg-6 d-flex">
                    <h4 className="m-0">All Pincodes</h4>
                </div>
                <div className="col-lg-4">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            onInput={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search..."
                            className="form-control ps-5"
                        />
                    </span>
                </div>
                <div className="col-lg-2 d-flex">
                    <button
                        type="button"
                        className="btn btn-primary btn-sm w-100 justify-content-center align-items-center"
                        onClick={() => openAddDialog()}
                    >
                        <i className="fas fa-plus"></i> {" "}Add
                    </button>
                </div>
            </div>
        </div>
    );
    const addDialogFooter = () => (
        <React.Fragment>
            <Button label="Close" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Submit" outlined severity="success" onClick={storeAds} />
        </React.Fragment>
    );

    const editDialogFooter = () => (
        <React.Fragment>
            <Button label="Close" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Update" outlined severity="success" onClick={() => updatePincode()} />
        </React.Fragment>
    );
    const updatePincode = async () => {
        await fetch(`${url}/api/admin/update_pincode`, {
            method: 'POST',
            body: JSON.stringify({
                id: selectedData._id,
                pincode: pincode ? pincode : selectedData.pincode,
                deliveryAddress: deliveryAddress ? deliveryAddress : selectedData.deliveryAddress
            }),
            headers: {
                "Content-Type": "application/json"
            }

        }).then((response) => response.json())
            .then((response) => {
                if (response.status === 201) {
                    setTime(true)
                    hideDialog();
                    setPincode("");
                    deliveryAddress("");
                    alert(response.result);
                } else {
                    hideDialog()
                    alert(response.result)
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    }

    const storeAds = async () => {
        await fetch(`${url}/api/admin/store_pincode`, {
            method: 'POST',
            body: JSON.stringify({ pincode, deliveryAddress }),
            headers: {
                "Content-Type": "application/json"
            }

        }).then((response) => response.json())
            .then((response) => {
                if (response.status === 201) {
                    setTime(true)
                    hideDialog();
                    setPincode("");
                    deliveryAddress("");
                    alert(response.result);
                } else {
                    hideDialog()
                    alert(response.result)
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };


    return (
        <>
            <div className="card px-3">
                <DataTable
                    value={allData}
                    dataKey="id"
                    paginator
                    rows={25}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
                    globalFilter={globalFilter}
                    header={headerComplete}
                >
                    <Column
                        field="#"
                        header="Sr. No."
                        bodyStyle={{
                            fontSize: 15,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                        body={(data, options) => options.rowIndex + 1}
                    ></Column>
                    <Column field="pincode" header="Pincode" sortable></Column>
                    <Column field="deliveryAddress" header="Address" sortable></Column>
                    <Column
                        header="Action"
                        body={filterApplyTemplate}
                        severity="success"
                    ></Column>
                </DataTable>

                <Dialog
                    visible={addModal}
                    style={{ width: "40rem" }}
                    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
                    header="Add Pincode"
                    modal
                    footer={addDialogFooter} onHide={hideDialog} >
                    <div className="row">
                        <div className="col-lg-12">
                            <label>Pincode </label>
                            <input type="text" className="form-control" onChange={(e) => setPincode(e.target.value)} defaultValue={pincode} placeholder="123456" />
                        </div>
                        <div className="col-lg-12">
                            <label>Delivery Address</label>
                            <input type="text" className="form-control" onChange={(e) => setDeliveryAddress(e.target.value)} defaultValue={deliveryAddress} placeholder="Enter Delivery Address" />
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    visible={editModal}
                    style={{ width: "40rem" }}
                    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
                    header="Edit Pincode"
                    modal
                    footer={editDialogFooter} onHide={hideDialog} >
                    <div className="row">
                        <div className="col-lg-12">
                            <label>Pincode </label>
                            <input type="text" className="form-control" onChange={(e) => setPincode(e.target.value)} defaultValue={selectedData.pincode} placeholder="123456" />
                        </div>
                        <div className="col-lg-12">
                            <label>Delivery Address</label>
                            <input type="text" className="form-control" onChange={(e) => setDeliveryAddress(e.target.value)} defaultValue={selectedData.deliveryAddress} placeholder="Enter Delivery Address" />
                        </div>
                    </div>
                </Dialog>

            </div>
        </>
    );
}


export default AllPincodes